












































import './scss/PopupDeleteCompositeFolder.scss';
import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { IDocument } from '@store/modules/project-documents/Interfaces';
import { ProjectDocumentsActions } from '@store/modules/project-documents/Types';

const projectDocumentsNamespace = namespace('storeProjectDocuments');
const NSProject = namespace('storeProject');

@Component({
    name: 'PopupDeleteCompositeFolder',

    components: {
        BasePopup: () => import('@components/popups/BasePopup.vue'),
        BaseComposite: () => import('@components/BaseComposite'),
    },
})
export default class DeleteWorkPopup extends Vue {
    $refs!: {
        basePopup: any;
    };

    @projectDocumentsNamespace.Getter('documentsWithoutRecursion') documentsWithoutRecursion!: IDocument[];
    @projectDocumentsNamespace.Action(ProjectDocumentsActions.A_REMOVE_DOCUMENT)
    deleteDocumentFolder!: (payload: { appId: number; id: string; }) => Promise<void>;
    @NSProject.Getter('projectId') projectId!: number;

    documentFolderToDelete = null as IDocument | null;
    popupOpen: boolean = false;

    get documentFolderToDeleteIsEmpty(): boolean {
        return !this.documentFolderToDelete?.children?.length;
    }

    get documentsIsFolder() {
        return this.documentsWithoutRecursion.filter(item => item.isFolder);
    }

    childrenIsFolder(documentFolder) {
        return documentFolder.children.filter((doc) => doc.isFolder);
    }

    openPopup() {
        if (!this.popupOpen) {
            this.$refs.basePopup.openPopup();
        }
        this.popupOpen = true;
    }

    closePopup() {
        this.resetDocumentFolderToDelete();
        this.popupOpen = false;
    }

    changeDocumentFolderToDelete(document: IDocument): void {
        const documentIsSelected = this.documentFolderToDelete === document;

        if (documentIsSelected) this.resetDocumentFolderToDelete();
        else this.selectWorkToDelete(document);
    }

    selectWorkToDelete(document: IDocument): void {
        this.documentFolderToDelete = document;
    }

    resetDocumentFolderToDelete(): void {
        this.documentFolderToDelete = null;
    }

    dispatchDeleteAction(): void {
        if (this.documentFolderToDelete) {
            this.deleteDocumentFolder({
                appId: this.projectId,
                id: this.documentFolderToDelete.uuid
            }).then(() => {
                this.$emit('confirm-delete-composite-folder', this.documentFolderToDelete);
                this.$refs.basePopup.closePopup();
            });
        }
    }
}
